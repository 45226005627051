import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Tag from '../../components/tag/Tag';
import CommentSection from '../../components/comment/CommentSection'; // Import the new CommentSection component
import '../../components/questions/QuestionList.css';
import './FullAnswer.css';
import API_ROUTES from '../apiRoutes';
import DOMPurify from 'dompurify';

const FullAnswer = () => {
  const [answer, setAnswer] = useState([]);
  const { answerId } = useParams();
  const answer_id = +answerId;

  useEffect(() => {
    axios.get(API_ROUTES.HOST+`/answer/${answer_id}`)
      .then((response) => {
        setAnswer(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the answer!', error);
      });
  }, [answer_id]);

  const handleVote = (answerId, tag) => {
    axios.post(API_ROUTES.HOST+'/vote', {
      answerId,
      tag
    }, { 
      withCredentials: true
    })
    .then(response => {
      // Update the local state to reflect the new vote count
      setAnswer(prevAnswer => ({
        ...prevAnswer,
        tags: prevAnswer.tags.map(t => 
          t.tag === tag ? { ...t, votes: response.data.status === 'Vote removed' ? t.votes - 1 : t.votes + 1 } : t
        )
      }));
    })
    .catch(error => {
      console.error('Error voting for tag:', error);
    });
  };

  const getPublicImageUrl = (url) => {
    return url.replace(/\/(small|verysmall)$/, '/public');
  };
  
  return (
    <div>
      <div className="card-full-answer txt-decoration-none">
        <div className='display-flex'>
      <Link className='quest-on-top display-flex txt-decoration-none' to={`/question/${answer.question_id}`} >
      <div className='m05em'><span className='label-small'>Have Better Design Idea, Click Question And Add Your Design </span></div> <div> {answer.questtitle}</div>
               </Link> 
               </div>
       

        <div className="content-container">
          <div className="title">
            <h3 className="card-color">{answer.title}</h3>
          </div>

          <div className="tags-container">
          <div className="tags">
          <div className="tags-header-label">
          <small className='label-small'>Vote Here </small> 
         </div>
            {answer.tags && answer.tags.length > 0 && answer.tags.map((tag, index) => (
              <div key={`${answer.id}-${index}`} className="tag-item">
                <Tag
                  label={tag.tag}
                  votes={tag.votes}
                  onVote={() => handleVote(answer.id, tag.tag)}
                />
              </div>
            ))}
          </div>
        </div>

          {answer.image_url && (
            <div className="drawing">
              <img 
               src={getPublicImageUrl(answer.image_url)}
                alt="System Design" 
                style={{ maxWidth: '700px', height: 'auto' }} 
              />
            </div>
          )}

          <div className="body">
            <p className="font16px" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer.body) }} />
          </div>
        </div>
        <div className="font14px display-flex postedby-color">
                  <div className="marginTop24">
                    <span className="font-small">Posted By </span>: <strong>{answer.username}</strong> <br></br>
                    <small>{new Date(answer.created).toLocaleString()}</small>
                  </div>
                </div>
      </div>

      <CommentSection answerId={answer.id} />
    </div>
  );
};

export default FullAnswer;
